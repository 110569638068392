import React, { useState } from "react";
import { Box, Button, Paper, CircularProgress } from "@mui/material";
import Alert from "@mui/material/Alert";
import QRImage from "../../assets/QR.svg";
import "./TotpVerification.css";
import { setTotp, verifyTotp } from "../../api/onboarding";
import TwoFactorAuthOtp from "../2FA/TwoFactorAuthOtp";

function validateTOTPValue(totp) {
  // Regular expression to match exactly 6 digits
  const regex = /^\d{6}$/;

  // Test if the provided string matches the regex
  return regex.test(totp);
}

const QRCodeComponent = ({ isTotpSetupDone, setIsTotpSetupDone }) => {
  const [showQR, setShowQR] = useState(false);
  const [QR, setQR] = useState(QRImage);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const validateTotp = async (otp) => {
    if (!validateTOTPValue(otp)) {
      setError("Please enter a 6 digit valid TOTP from authenticator app");
      return;
    }

    try {
      const response = await verifyTotp("TOTP", otp);
      console.log(response);
      setIsTotpSetupDone(true);
    } catch (error) {
      console.log({ error });
      setError(error?.response?.data?.message || "Invalid TOTP!");
    }
  };

  const handleShowQR = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await setTotp();
      console.log(response);
      setQR(response.qrCode);
      setShowQR(!showQR);
    } catch (error) {
      console.error({ error });
      setError(
        error?.response?.data?.message ||
          "Something went wrong. Try after some time."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      {isTotpSetupDone && <h1> TOTP setup SuccessFull</h1>}
      {!isTotpSetupDone && (
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              border: "1px solid #DBE0E5",
              borderRadius: "8px",
              width: 390,
              height: 390,
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                borderTop: "12px solid gray",
                borderLeft: "12px solid gray",
                width: 40,
                height: 40,
                borderTopLeftRadius: "8px",
              }}
            />
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 165,
                borderTop: "12px solid gray",
                width: 60,
              }}
            />
            <Box
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                borderTop: "12px solid gray",
                borderRight: "12px solid gray",
                width: 40,
                height: 40,
                borderTopRightRadius: "8px",
              }}
            />
            <Box
              sx={{
                position: "absolute",
                top: 165,
                right: 0,
                borderRight: "12px solid gray",
                height: 60,
              }}
            />
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                borderBottom: "12px solid gray",
                borderLeft: "12px solid gray",
                width: 40,
                height: 40,
                borderBottomLeftRadius: "8px",
              }}
            />
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                left: 165,
                borderBottom: "12px solid gray",
                width: 60,
              }}
            />
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                right: 0,
                borderBottom: "12px solid gray",
                borderRight: "12px solid gray",
                width: 40,
                height: 40,
                borderBottomRightRadius: "8px",
              }}
            />

            <Box
              sx={{
                position: "absolute",
                top: 165,
                left: 0,
                borderLeft: "12px solid gray",
                height: 60,
              }}
            />

            <Box
              sx={{
                top: "32.42px",
                left: "32.42px",
                position: "absolute",
              }}
            >
              <>
                <filter className={!showQR ? "blur" : "visible"}>
                  <img
                    src={QR} // Replace with your actual QR code URL
                    alt="QR Code"
                    style={{
                      width: "325.15px",
                      height: "325.15px",
                    }}
                  />
                </filter>
                {!showQR && (
                  <Button
                    onClick={handleShowQR}
                    sx={{
                      position: "absolute",
                      top: "143px",
                      left: "97px",
                      background: "#FFF",
                      fontSize: "18px",
                      color: "#292D32",
                      textAlign: "center",
                      borderRadius: "8px",
                    }}
                  >
                    {loading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "Tap to View"
                    )}
                  </Button>
                )}
              </>
            </Box>
          </Box>
          {error && (
            <Paper
              elevation={3}
              sx={{
                paddingBottom: "26px",
                paddingTop: "20px",
                mt: "35px",
                textAlign: "center",
              }}
            >
              <Alert severity="error"> {error} </Alert>
            </Paper>
          )}
          {showQR && (
            <Paper
              elevation={3}
              sx={{
                paddingBottom: "26px",
                paddingTop: "20px",
                mt: "35px",
                textAlign: "center",
              }}
            >
              <TwoFactorAuthOtp
                validateOTP={validateTotp}
                headingText={
                  "Please enter the 6 digit pin from the Authentication app"
                }
                containerStyle={{ mt: "35px" }}
              />
            </Paper>
          )}
        </Box>
      )}
    </Box>
  );
};

export default QRCodeComponent;
