import axios from "axios";

const API_BASE_URL =
  process.env.REACT_APP_LMS_API_BASE || "https://api.theloans.online";

const headers = {
  accept: "application/json",
  "Content-Type": "application/json",
};

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: headers,
});

// Function to verify onboarding
export const verifyOnboarding = async (token) => {
  try {
    const response = await axiosInstance.get(
      `/api/onboardings/verify/${token}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Function to reset password
export const resetPassword = async (newPassword) => {
  try {
    let token = localStorage.getItem("auth-token");
    const response = await axiosInstance.post(
      `/api/onboardings/${token}/password-reset`,
      {
        newPassword: newPassword,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error resetting password:", error);
    throw error;
  }
};

// Function to set TOTP
export const setTotp = async () => {
  try {
    let token = localStorage.getItem("auth-token");
    const response = await axiosInstance.get(
      `/api/onboardings/${token}/set_totp`
    );
    return response.data;
  } catch (error) {
    console.error("Error setting TOTP:", error);
    throw error;
  }
};

// Function to verify TOTP
export const verifyTotp = async (otpType, value) => {
  try {
    let token = localStorage.getItem("auth-token");
    const response = await axiosInstance.post(
      `/api/onboardings/${token}/verify_totp`,
      {
        otpType: otpType,
        value: value,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error verifying TOTP:", error);
    throw error;
  }
};

// Function to update personal info
export const updatePersonalInfo = async (personalInfo) => {
  try {
    let token = localStorage.getItem("auth-token");
    const response = await axiosInstance.post(
      `/api/onboardings/${token}/personal_info`,
      personalInfo
    );
    return response.data;
  } catch (error) {
    console.error("Error updating personal info:", error);
    throw error;
  }
};

// Function to update account details
export const updateAccountDetails = async (accountDetails) => {
  try {
    let token = localStorage.getItem("auth-token");
    const response = await axiosInstance.post(
      `/api/onboardings/${token}/account_details`,
      accountDetails
    );
    return response.data;
  } catch (error) {
    console.error("Error updating account details:", error);
    throw error;
  }
};

// Function to submit data
export const submitData = async () => {
  try {
    let token = localStorage.getItem("auth-token");
    const response = await axiosInstance.post(
      `/api/onboardings/${token}/submit`
    );
    return response.data;
  } catch (error) {
    console.error("Error submitting data:", error);
    throw error;
  }
};
