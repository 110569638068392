import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Select,
  TextField,
  MenuItem,
  Alert,
  CircularProgress,
} from "@mui/material";
import { updateAccountDetails } from "../../api/onboarding";
import { useNavigate } from "react-router-dom";

const BankDetails = () => {
  const [bank_details, setBankDetails] = useState({
    bankName: "",
    accountNumber: "",
    ifsc: "",
    accountType: "Savings",
    upiId: "",
  });

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("auth-token");
    if (!token) {
      alert("Invalid Session. Please contact admin!");
      navigate("/");
    }
    const employeeInfo = JSON.parse(localStorage.getItem("employee"));
    if (!employeeInfo) {
      alert("Invalid Session. Please contact admin!");
      navigate("/");
    }

    if (employeeInfo.account) {
      const bankDetails = employeeInfo.account;
      setBankDetails({
        bankName: bankDetails.bankName || "",
        accountNumber: bankDetails.accountNumber || "",
        ifsc: bankDetails.ifsc || "",
        accountType: bankDetails.accountType || "Savings",
        upiId: bankDetails.upiId || "",
      });
    }
  }, [navigate]);

  const handleSubmit = async () => {
    console.log(bank_details);

    if (!bank_details.bankName || !bank_details.accountNumber) {
      setError("Please enter all required fields!");
      return;
    }
    setLoading(true);
    setError(null);
    try {
      let response = await updateAccountDetails(bank_details);
      localStorage.setItem("employee", JSON.stringify(response.employee));
      navigate("/onboarding/totp_verify");
    } catch (error) {
      console.error({ error });
      setError(
        error?.response?.data?.message || "Please check your fields correctly!"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setBankDetails({
      ...bank_details,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Box>
      <Box
        sx={{
          border: "1px solid #c4c4c4",
          borderRadius: "8px",
          paddingY: "14px",
          textAlign: "center",
          marginTop: "28px",
          marginBottom: "16px",
        }}
      >
        <Typography
          sx={{
            color: "#6F6F6F",
            fontFamily: "Inter",
            fontSize: "18px",
            fontWeight: "500",
            lineHeight: "22px",
          }}
        >
          Bank Details
        </Typography>
      </Box>
      <Box
        sx={{
          textAlign: "left",
          display: "flex",
          gap: "29px",
        }}
      >
        <Box width={"350px"}>
          <Box sx={{ marginBottom: "14px" }}>
            <Typography className="input-heading" sx={{ marginBottom: "6px" }}>
              Bank Name<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              name="bankName"
              required
              variant="outlined"
              size="small"
              fullWidth
              defaultValue={""}
              inputProps={{
                placeholder: "Enter Bank Name",
              }}
              onChange={(e) => handleChange(e)}
            />
          </Box>
          <Box sx={{ marginBottom: "14px" }}>
            <Typography className="input-heading" sx={{ marginBottom: "6px" }}>
              IFSC Code<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              name="ifsc"
              required
              variant="outlined"
              size="small"
              fullWidth
              defaultValue={""}
              inputProps={{
                placeholder: "Enter IFSC Code",
              }}
              onChange={(e) => handleChange(e)}
            />
          </Box>
        </Box>
        <Box width={"350px"}>
          <Box sx={{ marginBottom: "14px" }}>
            <Typography className="input-heading" sx={{ marginBottom: "6px" }}>
              Account Number<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              name="accountNumber"
              required
              variant="outlined"
              size="small"
              fullWidth
              defaultValue={""}
              inputProps={{
                placeholder: "Enter account number",
              }}
              onChange={(e) => handleChange(e)}
            />
          </Box>
          <Box sx={{ marginBottom: "14px" }}>
            <Typography className="input-heading" sx={{ marginBottom: "6px" }}>
              UPI ID<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              name="upiId"
              required
              variant="outlined"
              size="small"
              fullWidth
              inputProps={{ placeholder: "Enter UPI ID" }}
              onChange={(e) => handleChange(e)}
            />
          </Box>
        </Box>
        <Box width={"350px"}>
          <Box sx={{ marginBottom: "14px" }}>
            <Typography className="input-heading" sx={{ marginBottom: "6px" }}>
              Account Type<span style={{ color: "red" }}>*</span>
            </Typography>
            <Select
              name="accountType"
              fullWidth
              required
              value={bank_details.accountType}
              defaultValue="Select"
              style={{ height: "40px" }}
              onChange={(e) => handleChange(e)}
            >
              <MenuItem value="Savings">Savings</MenuItem>
              <MenuItem value="Current">Current</MenuItem>
              <MenuItem value="Salary">Salary</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
          </Box>
        </Box>
      </Box>
      {error && <Alert severity="error">{error}</Alert>}
      <Box
        sx={{
          mt: "6px",
          display: "flex",
          justifyContent: "flex-end",
          gap: "19px",
        }}
      >
        <Button
          variant="contained"
          onClick={() => {
            handleSubmit();
          }}
          disabled={loading}
          sx={{
            textWrap: "nowrap",
            minWidth: "120px",
            height: "40px",
            fontSize: "14px",
            background: "#4680FF",
            borderRadius: "6.498px",
            boxShadow: "0px 1.625px 0px 0px rgba(0, 0, 0, 0.04)",
            textTransform: "none",
          }}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : "Next"}
        </Button>
      </Box>
    </Box>
  );
};

export default BankDetails;
