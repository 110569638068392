import React from "react";
import { Typography, Paper } from "@mui/material";

const Instructions = () => {
  const headingStyle = {
    color: "#353535",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
  };
  const textStyle = {
    color: "#6f6f6f",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "15px",
  };

  return (
    <Paper elevation={3} sx={{ padding: "17px 33px 25px 19px" }}>
      <Typography
        sx={{
          fontStyle: "italic",
          fontWeight: 400,
          lineHeight: "22px",
          fontSize: "18px",
          color: "#6f6f6f",
          textAlign: "center",
          textDecoration: "underline",
          marginBottom: "30px",
        }}
      >
        Read the instructions carefully and set up your TOTP (2-factor
        authentication)
      </Typography>

      <Typography sx={headingStyle}>
        Step 1: Download Authenticator App
      </Typography>
      <Typography sx={textStyle}>
        <ul>
          <li>Download any authenticator app from your device's app store.</li>
          <li>
            Recommended apps include
            <ul>
              <li>Google Authenticator</li>
              <li>Authy</li>
              <li>Microsoft Authenticator</li>
              <li>1Password</li>
            </ul>
          </li>
        </ul>
      </Typography>

      <Typography sx={headingStyle}>Step 2: Generate QR Code</Typography>
      <Typography sx={textStyle}>
        <ul>
          <li>Scroll Down to QR code section on this page.</li>
          <li>
            Click on the "Tap To View" button to reveal the QR code for
            validation.
          </li>
        </ul>
      </Typography>

      <Typography sx={headingStyle}>Step 3: Scan QR Code</Typography>
      <Typography sx={textStyle}>
        <ul>
          <li>
            Open the authenticator app on your device and click on Add button or
            + Icon or scan QR code button
          </li>
          <li>
            Use the app to scan the QR code displayed on this screen below
          </li>
        </ul>
      </Typography>

      <Typography sx={headingStyle}>
        Step 4: Save in Authenticator App
      </Typography>
      <Typography sx={textStyle}>
        <ul>
          <li>
            After scanning the QR code, click on the save button in the
            authenticator app to add the TOTP account.
          </li>
        </ul>
      </Typography>

      <Typography sx={headingStyle}>Step 5: Retrieve OTP </Typography>
      <Typography sx={textStyle}>
        <ul>
          <li>
            The authenticator app will start generating OTPs (One-Time
            Passwords) that refresh every 30 seconds.
          </li>
          <li> Note the current OTP displayed in the app.</li>
        </ul>
      </Typography>

      <Typography sx={headingStyle}>Step 6: Enter OTP & Confirm</Typography>
      <Typography sx={textStyle}>
        <ul>
          <li>
            Enter the OTP displayed in the authenticator app into the field just
            below the QR code on this screen below.
          </li>
          <li>
            Click the "Confirm" button to verify the OTP.
            <ul>
              <li>Once the OTP is successfully confirmed, click "Next".</li>
              <li>
                If the OTP is incorrect, retry by entering the latest OTP
                displayed in the authenticator app.
              </li>
            </ul>
          </li>
        </ul>
      </Typography>
      <Typography sx={headingStyle}>Step 8: Submit</Typography>
      <Typography sx={textStyle}>
        <ul>
          <li>
            Once the OTP is successfully confirmed, click the "Submit" button to
            complete the TOTP setup process.
          </li>
        </ul>
      </Typography>
    </Paper>
  );
};

export default Instructions;
