import "./App.css";
import MainLayout from "./components/Layout/MainLayout";
import HomePage from "./components/HomePage";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import VerifyDetails from "./components/Onboarding/VerifyDetails";
import PersonalDetailsForm from "./components/PersonalDetails/PersonalDetails";
import LoginOptions from "./components/LoginOption/LoginOptions";
import BankDetails from "./components/BankDetails/BankDetails";
import VerificationCompleted from "./components/VerificationCompleted/VerificationCompleted";
import TwoFactorAuthOtp from "./components/2FA/TwoFactorAuthOtp";
import TwoFactorAuthNumber from "./components/2FA/TwoFactorAuthNumber";
import PayEmi from "./components/PayEmi/PayEmi";
import EmiDetails from "./components/PayEmi/EmiDetails";
import PaymentSuccess from "./components/PayEmi/PaymentSuccess";
import GetLoanDetails from "./components/GetLoanDetails/GetLoanDetails";
import LoansData from "./components/GetLoanDetails/LoansData";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import ResetPasswordStep2 from "./components/ResetPassword/ResetPasswordStep2";
import ResetPasswordSuccess from "./components/ResetPassword/ResetPasswordSuccess";
import TOTPSetup from "./components/TotpVerification/TotpSetup";

function App() {
  console.log({ env: process.env });
  return (
    <Router>
      <div className="App">
        <MainLayout className="HomePageLayout">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/pay_emi" element={<PayEmi />} />
            <Route path="/emi_details" element={<EmiDetails />} />
            <Route path="/payment_success" element={<PaymentSuccess />} />
            <Route path="/loan_details" element={<GetLoanDetails />} />
            <Route path="/loan_data" element={<LoansData />} />
            <Route path="/reset_password" element={<ResetPassword />} />
            <Route
              path="/reset_password_success"
              element={<ResetPasswordSuccess />}
            />
            <Route path="/onboarding/:token" element={<VerifyDetails />} />
            <Route
              path="/onboarding/update_password"
              element={<ResetPasswordStep2 />}
            />
            <Route
              path="/onboarding/personal_details"
              element={<PersonalDetailsForm />}
            />
            <Route path="/onboarding/bank_details" element={<BankDetails />} />
            <Route path="/onboarding/totp_verify" element={<TOTPSetup />} />
            <Route
              path="/onboarding/login_options"
              element={<LoginOptions />}
            />
            <Route
              path="/onboarding/verification_completed"
              element={<VerificationCompleted />}
            />
            <Route
              path="/onboarding/2fa_number"
              element={<TwoFactorAuthNumber />}
            />
            <Route path="/onboarding/2fa_otp" element={<TwoFactorAuthOtp />} />
          </Routes>
        </MainLayout>
      </div>
    </Router>
  );
}

export default App;
