import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { verifyOnboarding } from "../../api/onboarding";
import { Grid, Box, Typography, TextField, Button } from "@mui/material";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
import MyLoader from "../Loader/MyLoader";
import "./VerifyDetails.css";

const VerifyDetails = () => {
  const { token } = useParams();
  const [otp, setOtp] = useState("");
  const [isMobileVerified, setIsMobileVerified] = useState(true);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const effectRan = useRef(false);

  const [employee, setEmployee] = useState({
    firstName: "Rohit",
    lastName: "Gangwal",
    role: "admin",
    mobile: "9298345678",
    email: "rohit@galoans.in",
  });

  useEffect(() => {
    if (!token || !navigate || effectRan.current) {
      return;
    }
    const verifyToken = async () => {
      setLoader(true);
      try {
        const response = await verifyOnboarding(token);
        console.log(response.employee);
        setEmployee({
          firstName: response.employee.firstName,
          lastName: response.employee.lastName,
          role: response.employee?.roles || "employee",
          mobile: response.employee.mobile,
          email: response.employee.email,
        });
        localStorage.setItem("auth-token", token);
        localStorage.setItem("employee", JSON.stringify(response.employee));
        return;
      } catch (error) {
        alert(`Failed To verify, ${error?.response?.data?.message}`);
        navigate("/");
        return;
      } finally {
        setLoader(false);
      }
    };

    verifyToken();
    effectRan.current = true;
  }, [token, navigate]);

  const onSendOtp = () => {
    console.log("Send Otp Clicked");
    setIsOtpSent(true);
  };

  const onVerifyOtp = () => {
    console.log("Verify Otp Clicked");
    setIsMobileVerified(true);
  };

  const onSubmit = () => {
    console.log("Submit Clicked");
    navigate("/onboarding/update_password");
  };

  return (
    <div>
      {loader ? (
        <MyLoader />
      ) : (
        <Grid
          container
          sx={{
            display: "flex",
            gap: "60px",
            flexWrap: "nowrap",
            mt: "38px",
            textAlign: "left",
            pl: "79px",
            pr: "86px",
          }}
        >
          <Grid
            item
            xs={6}
            sx={{ display: "flex", gap: "16px", flexDirection: "column" }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "14px",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography
                  className="input-heading"
                  sx={{ marginBottom: "6px" }}
                >
                  First Name
                </Typography>
                <TextField
                  className="input-field"
                  value={employee.firstName}
                  variant="outlined"
                  size="small"
                  sx={{ width: "263px" }}
                  disabled
                />
              </Box>
            </Box>
            <Box>
              <Typography
                className="input-heading"
                sx={{ marginBottom: "6px" }}
              >
                Role
              </Typography>
              <TextField
                className="input-field"
                value={employee.role}
                variant="outlined"
                size="small"
                fullWidth
                disabled
              />
            </Box>
            <Box>
              <Typography
                className="input-heading"
                sx={{ marginBottom: "6px" }}
              >
                Mobile Number
              </Typography>
              <TextField
                className="input-field"
                value={employee.mobile}
                variant="outlined"
                size="small"
                fullWidth
                disabled
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", gap: "16px", flexDirection: "column" }}
          >
            <Box>
              <Typography
                className="input-heading"
                sx={{ marginBottom: "6px" }}
              >
                Last Name
              </Typography>
              <TextField
                className="input-field"
                value={employee.lastName}
                variant="outlined"
                size="small"
                fullWidth
                disabled
              />
            </Box>
            <Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  className="input-heading"
                  sx={{ marginBottom: "6px" }}
                >
                  Email
                </Typography>
                <Box sx={{ display: "flex", gap: "7.45px" }}>
                  <DoneAllOutlinedIcon sx={{ color: "#1BC58C" }} />
                  <Typography
                    sx={{
                      marginBottom: "6px",
                      fontStyle: "italic",
                      color: "#1BC58C",
                    }}
                  >
                    Verified
                  </Typography>
                </Box>
              </Box>
              <TextField
                className="input-field"
                value={employee.email}
                variant="outlined"
                size="small"
                fullWidth
                disabled
              />
            </Box>
            <Box>
              {!isMobileVerified && (
                <Typography
                  className="input-heading"
                  sx={{ marginBottom: "6px" }}
                >
                  Verify Mobile Number
                </Typography>
              )}
              {isMobileVerified && (
                <Box sx={{ display: "flex", gap: "7.45px" }}>
                  <DoneAllOutlinedIcon sx={{ color: "#1BC58C" }} />
                  <Typography
                    sx={{
                      marginBottom: "6px",
                      fontStyle: "italic",
                      color: "#1BC58C",
                    }}
                  >
                    Verified
                  </Typography>
                </Box>
              )}

              <Box
                sx={{
                  display: "flex",
                  gap: "16px",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <TextField
                  className="input-field"
                  inputProps={{ placeholder: "Enter OTP" }}
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled={isMobileVerified}
                />
                <Button
                  variant="contained"
                  disabled={isMobileVerified}
                  sx={{
                    textWrap: "nowrap",
                    minWidth: "120px",
                    height: "40px",
                    fontSize: "14px",
                    background: "#4680FF",
                    borderRadius: "6.498px",
                    boxShadow: "0px 1.625px 0px 0px rgba(0, 0, 0, 0.04)",
                    textTransform: "none",
                  }}
                  onClick={isOtpSent ? onVerifyOtp : onSendOtp}
                >
                  {isOtpSent ? "Verify" : "Send OTP"}
                </Button>
              </Box>
            </Box>
            <Box
              sx={{
                mt: "100px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                disabled={!isMobileVerified}
                onClick={onSubmit}
                variant="contained"
                sx={{
                  textWrap: "nowrap",
                  minWidth: "120px",
                  height: "40px",
                  fontSize: "14px",
                  background: "#4680FF",
                  borderRadius: "6.498px",
                  boxShadow: "0px 1.625px 0px 0px rgba(0, 0, 0, 0.04)",
                  textTransform: "none",
                  "&.Mui-disabled": {
                    color: "white",
                  },
                }}
              >
                Next
              </Button>
            </Box>
          </Grid>
        </Grid>
      )}
    </div>
  );
};
export default VerifyDetails;
