import { Box, Typography } from "@mui/material";
import CompletedIcon from "../../assets/doneIcon.svg";

const VerificationCompleted = ({ message }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyItems: "center",
        alignItems: "center",
        flexDirection: "column",
        mt: "50px",
        gap: "29px",
      }}
    >
      <img src={CompletedIcon} alt="done" />
      <Typography
        sx={{
          fontSize: "20px",
          fontWeight: 700,
          fontStyle: "italic",
          color: "#6F6F6F",
        }}
      >
        {message || "Your profile details submitted successfully."}
      </Typography>
    </Box>
  );
};

export default VerificationCompleted;
