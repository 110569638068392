import React, { useState } from "react";
import CommonForm from "../CommonComponent/CommonForm";
import { useNavigate } from "react-router-dom";
import { resetPassword } from "../../api/onboarding";
import { validatePassword } from "../../utils/password_util";
import { Alert } from "@mui/material";

const ResetPasswordStep2 = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const handleResetPassword = async () => {
    try {
      console.log({ formData });
      if (validatePassword(formData.newPassword)) {
        setError(validatePassword(formData.newPassword));
        return;
      }

      if (formData.newPassword !== formData.confirmPassword) {
        setError("Passwords do not match");
        return;
      }

      const response = await resetPassword(formData.newPassword);
      console.log({ response });
      navigate("/onboarding/personal_details");
    } catch (error) {
      console.error("Error resetting password:", error);
      throw error;
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const fields = [
    {
      id: "newPassword",
      name: "New Password",
      inputPlaceholder: "Enter New Password",
      type: "password",
    },
    {
      id: "confirmPassword",
      name: "Confirm Password",
      inputPlaceholder: "Confirm New Password",
      type: "password",
    },
  ];
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {error && <Alert severity="error">{error}</Alert>}
      <CommonForm
        buttonOnClick={handleResetPassword}
        handleChange={handleChange}
        fields={fields}
        heading={"Reset Password"}
        buttonName="Reset"
      />
    </div>
  );
};

export default ResetPasswordStep2;
