import { useRef } from "react";
import { Box, Typography, TextField, Button } from "@mui/material";

const TwoFactorAuthOtp = ({ validateOTP, headingText, containerStyle }) => {
  const inputs = useRef([]);

  const verifyTOTP = async () => {
    let otpString = "";
    inputs.current.forEach((input) => {
      otpString += input.value;
    });
    console.log("going to verify", otpString);
    await validateOTP(otpString);
  };
  const handleInputChange = (e, index) => {
    const { value } = e.target;

    if (value.length > 1) {
      e.target.value = value.slice(0, 1);
    }

    if (value && index < 5) {
      inputs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      inputs.current[index - 1].focus();
    }
  };
  return (
    <Box
      sx={{
        mt: "60px",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        ...containerStyle,
      }}
    >
      <Typography
        sx={{
          fontSize: "24px",
          fontWeight: 600,
          lineHeight: "32px",
          textAlign: "center",
          mb: "15px",
          width: "450px",
        }}
      >
        2-factor authentication
      </Typography>
      <Box
        sx={{
          width: "450px",
          height: "44px",
          fontSize: "14px",
          fontWeight: "400px",
          color: "#5B6B79",
          mb: "77px",
        }}
      >
        {headingText ||
          "Please enter the 6 digit pin sent to your registered Whatsapp Number (+91 *******342)"}
      </Box>
      <Box sx={{ mb: "40px", height: "45px", display: "flex", gap: "29px" }}>
        {[0, 1, 2, 3, 4, 5].map((_, index) => (
          <TextField
            key={index}
            inputRef={(el) => (inputs.current[index] = el)}
            variant="outlined"
            defaultValue={"0"}
            inputProps={{
              style: { textAlign: "center", fontSize: "18px" },
            }}
            onChange={(e) => handleInputChange(e, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            sx={{
              width: "45px",
              height: "45px",
              "& .MuiInputBase-root": {
                width: "40px",
                height: "40px",
                border: "1px solid #EFEFEF",
              },
            }}
          />
        ))}
      </Box>
      <Button
        variant="contained"
        sx={{
          textWrap: "nowrap",
          minWidth: "208px",
          height: "44px",
          fontSize: "14px",
          background: "#4680FF",
          borderRadius: "6.498px",
          boxShadow: "0px 1.625px 0px 0px rgba(0, 0, 0, 0.04)",
          textTransform: "none",
        }}
        onClick={verifyTOTP}
      >
        Confirm
      </Button>
    </Box>
  );
};

export default TwoFactorAuthOtp;
