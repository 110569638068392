// src/components/HomePage.jsx

import React from "react";
import { Box, Paper, Typography } from "@mui/material";
import logo from "../../assets/logo.png";

const MainLayout = ({ children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "90vh",
        backgroundColor: "#f0f2f5",
        paddingY: "5vh",
        paddingX: "5vw",
      }}
    >
      <Paper
        sx={{
          maxWidth: "80vw",
          minWidth: "1005px",
          minHeight: "701px",
          padding: "40px",
          boxSizing: "border-box",
          border: "1px solid #CFD1D4",
          borderRadius: "8px",
          position: "relative",
        }}
      >
        <Box sx={{ textAlign: "center" }}>
          <img
            src={logo}
            alt="Serveka Logo"
            style={{
              width: "30%",
              marginBottom: "20px",
              flexShrink: 0,
            }}
          />
          <Typography
            style={{
              marginTop: "24px",
              color: "#6F6F6F",
              textAlign: "center",
              fontFamily: "Inter",
              fontSize: "18px",
              fontStyle: "italic",
              fontWeight: "500",
              lineHeight: "22px",
            }}
          >
            Hello User,
          </Typography>
          <Typography
            style={{
              marginTop: "17px",
              color: "#4680FF",
              textAlign: "center",
              fontFamily: "Inter",
              fontSize: "24px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "22px" /* 91.667% */,
            }}
          >
            Welcome to Serveka LMS
          </Typography>
          <Typography
            style={{
              marginTop: "22px",
              color: "#6F6F6F",
              textAlign: "center",
              fontFamily: "Inter",
              fontSize: "18px",
              fontStyle: "italic",
              fontWeight: 400,
              lineHeight: "22px" /* 122.222% */,
            }}
          >
            Let's complete your profile here
          </Typography>
        </Box>
        <Box>{children}</Box>
      </Paper>
    </Box>
  );
};

export default MainLayout;
