import CommonDetails from "../CommonComponent/CommonDetails";

const LoansData = () => {
  const data = [
    { label: "Total EMIs", value: "12" },
    { label: "Pending EMIs", value: "4" },
    { label: "Due EMIs", value: "2" },
    { label: "Next EMIs", value: "12-12-2024" },
  ];
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <CommonDetails data={data} buttonName="Pay" />
    </div>
  );
};

export default LoansData;
