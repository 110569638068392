import { Box, Typography } from "@mui/material";

const LoginOptions = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        paddingX: "60px",
        marginTop: "50px",
      }}
    >
      <Typography
        sx={{
          width: "300px",
          height: "24px",
          fontWeight: 600,
          fontSize: "18px",
          lineHeight: "22px",
          color: "#353535",
          marginBottom: "17px",
          textAlign: "left"
        }}
      >
        Select another option to login
      </Typography>
      <Option title="Using WhatsApp OTP" />
      <Option title="Using Email OTP" style={{ border: "2px solid #4680FF" }} />
      <Option title="Using SMS OTP" />
    </Box>
  );
};

const Option = ({ title, style }) => {
  return (
    <Box
      sx={{
        height: "70px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        border: "1px solid #CFD1D4",
        borderRadius: "8px",
        marginBottom: "14px",
        paddingLeft: "25px",
        ...style,
      }}
    >
      <Typography
        sx={{
          width: "300px",
          height: "24px",
          fontWeight: 400,
          fontSize: "18px",
          lineHeight: "22px",
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};

export default LoginOptions;
