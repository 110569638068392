import { Typography, Box, Button } from "@mui/material";

const CommonDetails = (props) => {
  return (
    <Box
      sx={{
        width: "363px",
        borderRadius: "11.538px",
        border: "1.282px solid rgba(0, 0, 0, 0.25)",
        background: "var(--light01-100, #FFF)",
        boxShadow: "0px 2px 0px 0px rgba(0, 0, 0, 0.15)",
        textAlign: "left",
        mt: "48px",
        padding: "36px 44px 40px 40px",
        ...props.containerStyle,
      }}
    >
      <Box display="flex" flexDirection="column" gap="20px">
        {props.data?.map((item) => (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              ...item.containerStyle,
            }}
          >
            <Typography
              sx={{ fontSize: "14px", color: "#9FA2AA", ...item.labelStyle }}
            >
              {item.label}
            </Typography>
            <Typography
              sx={{
                minWidth: "100px",
                fontSize: "16px",
                fontWeight: 600,
                ...item.valueStyle,
              }}
            >
              {item.value}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: "35px",
          ...props.buttonContainerStyle,
        }}
      >
        <Button
          variant="contained"
          sx={{
            textWrap: "nowrap",
            width: "160px",
            height: "40px",
            fontSize: "14px",
            background: "#4680FF",
            borderRadius: "6.498px",
            boxShadow: "0px 1.625px 0px 0px rgba(0, 0, 0, 0.04)",
            textTransform: "none",
            ...props.buttonStyle,
          }}
          onClick={props.buttonOnClick}
        >
          {props.buttonName}
        </Button>
      </Box>
    </Box>
  );
};

export default CommonDetails;
