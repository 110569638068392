import { Box, Button, TextField, Typography } from "@mui/material";

const CommonForm = (props) => {
  return (
    <Box
      sx={{
        width: "363px",
        borderRadius: "11.538px",
        border: "1.282px solid rgba(0, 0, 0, 0.25)",
        background: "var(--light01-100, #FFF)",
        boxShadow: "0px 2px 0px 0px rgba(0, 0, 0, 0.15)",
        textAlign: "left",
        mt: "48px",
        padding: "14px 17px 23px 17px",
        ...props.containerStyle,
      }}
    >
      <Typography
        className="input-heading"
        sx={{ fontSize: "20px", mb: "12px", ...props.headingStyle }}
      >
        {props.heading}
      </Typography>
      {props.fields.map((field) => (
        <Box sx={{ mb: "25px", ...field.containerStyle }}>
          <Typography
            sx={{
              color: "var(--day-subtextcolor, #5B6B79)",
              fontSize: "17.526px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "22.371px",
              mb: "7px",
              ...field.textStyle,
            }}
          >
            {field.name}
          </Typography>
          <TextField
            name={field.id}
            fullWidth
            sx={{
              height: "50px",
              ...field.inputStyle,
            }}
            inputProps={{
              placeholder: field.inputPlaceholder,
            }}
            onChange={props.handleChange}
            type={field.type}
          />
        </Box>
      ))}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: "42px",
          ...props.buttonContainerStyle,
        }}
      >
        <Button
          variant="contained"
          sx={{
            textWrap: "nowrap",
            width: "160px",
            height: "40px",
            fontSize: "14px",
            background: "#4680FF",
            borderRadius: "6.498px",
            boxShadow: "0px 1.625px 0px 0px rgba(0, 0, 0, 0.04)",
            textTransform: "none",
            ...props.buttonStyle,
          }}
          onClick={props.buttonOnClick}
        >
          {props.buttonName}
        </Button>
      </Box>
    </Box>
  );
};

export default CommonForm;
