import React from "react";
import "./HomePage.css";
import { useNavigate } from "react-router-dom";

const HomePage = () => {
  const navigate = useNavigate();

  return (
    <div className="HomePage">
      <button className="btn btn1" onClick={() => navigate("/pay_emi")}>
        Pay EMI
      </button>
      <button className="btn" onClick={() => navigate("/loan_details")}>
        Get Loan Details
      </button>
      <button className="btn" onClick={() => navigate("/reset_password")}>
        Reset Password
      </button>
    </div>
  );
};
export default HomePage;
