import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  TextField,
  Select,
  MenuItem,
  Box,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import { updatePersonalInfo } from "../../api/onboarding";
import { useNavigate } from "react-router-dom";
import "./PersonalDetails.css";

const PersonalDetailsForm = () => {
  const navigate = useNavigate();
  const [dob, setDOB] = useState(dayjs("2022-04-17"));
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    prefix: "",
    motherSName: null,
    fatherName: null,
    category: "",
    qualification: null,
    maritalStatus: "",
    dependentOthers: 0,
    religion: null,
    currentResidenceType: null,
    drivingLicence: null,
    gender: "",
    placeOfBirth: null,
    yearsAtCurrentResidence: 0,
    dependentChildren: 0,
    panNumber: null,
    passportNumber: "",
    note: null,
    aadhar_number: "",
  });

  useEffect(() => {
    const token = localStorage.getItem("auth-token");
    if (!token) {
      alert("Invalid Session. Please contact admin!");
      navigate("/");
    }
    const employeeInfo = JSON.parse(localStorage.getItem("employee"));
    console.log(employeeInfo);
    if (!employeeInfo) {
      alert("Invalid Session. Please contact admin!");
      navigate("/");
    }
    setFirstName(employeeInfo.firstName);
    setLastName(employeeInfo.lastName);
    if (employeeInfo.personalDetails) {
      const personalInfo = employeeInfo.personalDetails;
      setFormData({
        prefix: personalInfo.prefix || "",
        motherSName: personalInfo.motherSName || null,
        fatherName: personalInfo.fatherOrSpouseName || null,
        category: personalInfo.category || "",
        qualification: personalInfo.qualification || null,
        maritalStatus: personalInfo.maritalStatus || "",
        dependentOthers: personalInfo.numberOfDependentOthers || 0,
        religion: personalInfo.religion || null,
        currentResidenceType: personalInfo.currentResidence || null,
        drivingLicence: personalInfo.drivingLicence || null,
        gender: personalInfo.gender || "",
        placeOfBirth: personalInfo.placeOfBirth || null,
        yearsAtCurrentResidence:
          personalInfo.numberOfYearsAtCurrentResidence || 0,
        dependentChildren: personalInfo.numberOfDependentChildren || 0,
        panNumber: personalInfo.panNumber || null,
        passportNumber: personalInfo.passportNumber || "",
        note: personalInfo.notes || null,
        aadhar_number: personalInfo.aadhar_number || "",
      });
    }
  }, [navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    for (const key in formData) {
      if (
        (formData[key] === null || formData[key] === "") &&
        !["note", "passportNumber", "drivingLicense"].includes(key)
      ) {
        alert(`Please fill in the ${key} field.`);
        return;
      }
    }
    setLoading(true);

    const personalInfo = {
      category: formData.category,
      currentResidence: formData.currentResidenceType,
      dateOfBirth: new Date(dob).toISOString(),
      fatherOrSpouseName: formData.fatherName,
      fullName: `${formData.prefix} ${firstName} ${lastName}`,
      gender: formData.gender,
      maritalStatus: formData.maritalStatus,
      notes: formData.note,
      numberOfDependentChildren: parseInt(formData.dependentChildren) || 0,
      numberOfDependentOthers: parseInt(formData.dependentOthers) || 0,
      numberOfYearsAtCurrentResidence:
        parseInt(formData.yearsAtCurrentResidence) || 0,
      panNumber: formData.panNumber,
      placeOfBirth: formData.placeOfBirth,
      prefix: formData.prefix,
      qualification: formData.qualification,
      religion: formData.religion,
      motherSName: formData.motherSName,
      aadharNumber: formData.aadhar_number,
      passportNumber: formData.passportNumber,
      drivingLicenseNumber: formData.drivingLicenseNumber,
    };
    const isConfirmed = window.confirm("Are you sure you want to submit?");
    if (!isConfirmed) {
      return;
    }
    try {
      console.log(personalInfo);
      const response = await updatePersonalInfo(personalInfo);
      localStorage.setItem("employee", JSON.stringify(response.employee));
      alert("Personal information updated successfully");
      navigate("/onboarding/bank_details");
    } catch (error) {
      console.error("Error updating personal info:", { error });
      alert(
        `Failed to update personal info: ${
          error?.response?.data?.message || error.message
        }`
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box overflow={"hidden"}>
      <Box
        sx={{
          border: "1px solid #c4c4c4",
          borderRadius: "8px",
          paddingY: "14px",
          textAlign: "center",
          marginTop: "28px",
          marginBottom: "16px",
        }}
      >
        <Typography
          sx={{
            color: "#6F6F6F",
            fontFamily: "Inter",
            fontSize: "18px",
            fontWeight: "500",
            lineHeight: "22px",
          }}
        >
          Personal Details
        </Typography>
      </Box>
      <Box
        sx={{
          textAlign: "left",
          display: "flex",
          gap: "29px",
        }}
      >
        <Box width={"350px"}>
          <Box sx={{ display: "flex", gap: "13px", marginBottom: "14px" }}>
            <Box>
              <Typography
                className="input-heading"
                sx={{ marginBottom: "6px" }}
              >
                Prefix<span style={{ color: "red" }}>*</span>
              </Typography>
              <Select
                name="prefix"
                value={formData.prefix}
                onChange={handleChange}
                required
                style={{ height: "40px", width: "80px" }}
              >
                <MenuItem value="Mr">Mr</MenuItem>
                <MenuItem value="Mrs">Mrs</MenuItem>
                <MenuItem value="Ms">Ms</MenuItem>
                <MenuItem value="Dr">Dr</MenuItem>
                <MenuItem value="Prof">Prof</MenuItem>
                <MenuItem value="Eng">Eng</MenuItem>
                <MenuItem value="Capt">Capt</MenuItem>
              </Select>
            </Box>
            <Box>
              <Typography
                className="input-heading"
                sx={{ marginBottom: "6px" }}
              >
                First Name<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                name="firstName"
                variant="outlined"
                size="small"
                fullWidth
                sx={{ width: "257px" }}
                value={firstName}
                disabled
              />
            </Box>
          </Box>
          <Box sx={{ marginBottom: "14px" }}>
            <Typography className="input-heading" sx={{ marginBottom: "6px" }}>
              Mother's Name<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              name="motherSName"
              variant="outlined"
              size="small"
              fullWidth
              value={formData.motherSName}
              onChange={handleChange}
            />
          </Box>
          <Box sx={{ marginBottom: "14px" }}>
            <Typography className="input-heading" sx={{ marginBottom: "6px" }}>
              Category<span style={{ color: "red" }}>*</span>
            </Typography>
            <Select
              name="category"
              fullWidth
              required
              value={formData.category}
              onChange={handleChange}
              style={{ height: "40px" }}
            >
              <MenuItem value="Select">Select</MenuItem>
              <MenuItem value="General">General</MenuItem>
              <MenuItem value="OtherBackwardClasses">OBC</MenuItem>
              <MenuItem value="ScheduledCastes">SC</MenuItem>
              <MenuItem value="ScheduledTribes">ST</MenuItem>
              <MenuItem value="EconomicallyWeakerSections">EWS</MenuItem>
            </Select>
          </Box>
          <Box sx={{ marginBottom: "14px" }}>
            <Typography className="input-heading" sx={{ marginBottom: "6px" }}>
              Qualification<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              name="qualification"
              variant="outlined"
              size="small"
              fullWidth
              value={formData.qualification}
              onChange={handleChange}
              inputProps={{ placeholder: "Enter your qualification" }}
            />
          </Box>
          <Box sx={{ marginBottom: "14px" }}>
            <Typography className="input-heading" sx={{ marginBottom: "6px" }}>
              Marital Status<span style={{ color: "red" }}>*</span>
            </Typography>
            <Select
              name="maritalStatus"
              fullWidth
              required
              value={formData.maritalStatus}
              onChange={handleChange}
              style={{ height: "40px" }}
            >
              <MenuItem value="Select">Select</MenuItem>
              <MenuItem value="Married">Married</MenuItem>
              <MenuItem value="Single">Single</MenuItem>
            </Select>
          </Box>
          <Box sx={{ marginBottom: "14px" }}>
            <Typography className="input-heading" sx={{ marginBottom: "6px" }}>
              Number of Dependent Others
            </Typography>
            <TextField
              name="dependentOthers"
              variant="outlined"
              size="small"
              fullWidth
              value={formData.dependentOthers}
              onChange={handleChange}
              inputProps={{ placeholder: "Enter Number of Dependent Others" }}
            />
          </Box>
        </Box>
        <Box width={"350px"}>
          <Box sx={{ marginBottom: "14px" }}>
            <Typography className="input-heading" sx={{ marginBottom: "6px" }}>
              Last Name<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              name="lastName"
              variant="outlined"
              size="small"
              fullWidth
              value={lastName}
              disabled
            />
          </Box>
          <Box sx={{ marginBottom: "14px", marginTop: "14px" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ width: "100%", marginTop: "14px" }}
                label={
                  <Typography
                    className="input-heading"
                    sx={{ marginBottom: "6px" }}
                  >
                    Date of Birth<span style={{ color: "red" }}>*</span>
                  </Typography>
                }
                value={dob}
                onChange={(newValue) => setDOB(newValue)}
              />
            </LocalizationProvider>
          </Box>
          <Box sx={{ marginBottom: "14px" }}>
            <Typography className="input-heading" sx={{ marginBottom: "6px" }}>
              Religion<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              name="religion"
              variant="outlined"
              size="small"
              fullWidth
              value={formData.religion}
              onChange={handleChange}
              inputProps={{ placeholder: "Enter your religion" }}
            />
          </Box>
          <Box sx={{ marginBottom: "14px" }}>
            <Typography className="input-heading" sx={{ marginBottom: "6px" }}>
              Current Residence Type<span style={{ color: "red" }}>*</span>
            </Typography>
            <Select
              name="currentResidenceType"
              fullWidth
              required
              value={formData.currentResidenceType}
              onChange={handleChange}
              style={{ height: "40px" }}
            >
              <MenuItem value="Select">Select</MenuItem>
              <MenuItem value="Owned">Owned</MenuItem>
              <MenuItem value="Rented">Rented</MenuItem>
              <MenuItem value="Company Provided">Company Provided</MenuItem>
            </Select>
          </Box>
          <Box sx={{ marginBottom: "14px" }}>
            <Typography className="input-heading" sx={{ marginBottom: "6px" }}>
              Father's Name
            </Typography>
            <TextField
              name="fatherName"
              variant="outlined"
              size="small"
              fullWidth
              value={formData.fatherName}
              onChange={handleChange}
              inputProps={{ placeholder: "Enter Father's Name" }}
            />
          </Box>
          <Box sx={{ marginBottom: "14px" }}>
            <Typography className="input-heading" sx={{ marginBottom: "6px" }}>
              Driving License Number
            </Typography>
            <TextField
              name="drivingLicence"
              variant="outlined"
              size="small"
              fullWidth
              value={formData.drivingLicence}
              onChange={handleChange}
              inputProps={{ placeholder: "Enter Driving License Number" }}
            />
          </Box>
        </Box>
        <Box width={"350px"}>
          <Box sx={{ marginBottom: "14px" }}>
            <Typography className="input-heading" sx={{ marginBottom: "6px" }}>
              Aadhar Number<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              name="aadhar_number"
              variant="outlined"
              size="small"
              fullWidth
              value={formData.aadhar_number}
              onChange={handleChange}
            />
          </Box>
          <Box sx={{ marginBottom: "14px" }}>
            <Typography className="input-heading" sx={{ marginBottom: "6px" }}>
              Gender<span style={{ color: "red" }}>*</span>
            </Typography>
            <Select
              name="gender"
              fullWidth
              required
              value={formData.gender}
              onChange={handleChange}
              style={{ height: "40px" }}
            >
              <MenuItem value="Select">Select</MenuItem>
              <MenuItem value="Male">Male</MenuItem>
              <MenuItem value="Female">Female</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
          </Box>
          <Box sx={{ marginBottom: "14px" }}>
            <Typography className="input-heading" sx={{ marginBottom: "6px" }}>
              Place of Birth<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              name="placeOfBirth"
              variant="outlined"
              size="small"
              fullWidth
              value={formData.placeOfBirth}
              onChange={handleChange}
              inputProps={{ placeholder: "Enter your Place of Birth" }}
            />
          </Box>
          <Box sx={{ marginBottom: "14px" }}>
            <Typography className="input-heading" sx={{ marginBottom: "6px" }}>
              Number of Years at Current Residence
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              name="yearsAtCurrentResidence"
              variant="outlined"
              size="small"
              fullWidth
              value={formData.yearsAtCurrentResidence}
              onChange={handleChange}
              inputProps={{ placeholder: "Enter Number of years" }}
            />
          </Box>
          <Box sx={{ marginBottom: "14px" }}>
            <Typography className="input-heading" sx={{ marginBottom: "6px" }}>
              Number of Dependent Children
            </Typography>
            <TextField
              name="dependentChildren"
              variant="outlined"
              size="small"
              fullWidth
              value={formData.dependentChildren}
              onChange={handleChange}
              inputProps={{ placeholder: "Enter Number of Dependent Children" }}
            />
          </Box>
          <Box sx={{ marginBottom: "14px" }}>
            <Typography className="input-heading" sx={{ marginBottom: "6px" }}>
              Pan Number
            </Typography>
            <TextField
              name="panNumber"
              variant="outlined"
              size="small"
              fullWidth
              value={formData.panNumber}
              onChange={handleChange}
              inputProps={{ placeholder: "Enter PAN Number" }}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", gap: "29px", textAlign: "left" }}>
        <Box sx={{ marginBottom: "14px" }}>
          <Typography className="input-heading" sx={{ marginBottom: "6px" }}>
            Passport Number
          </Typography>
          <TextField
            name="passportNumber"
            variant="outlined"
            size="small"
            sx={{ width: "350px" }}
            value={formData.passportNumber}
            onChange={handleChange}
            inputProps={{ placeholder: "Enter Passport Number" }}
          />
        </Box>
        <Box sx={{ marginBottom: "14px" }}>
          <Typography className="input-heading" sx={{ marginBottom: "6px" }}>
            Note
          </Typography>
          <TextField
            name="note"
            variant="outlined"
            size="small"
            sx={{ width: "729px" }}
            value={formData.note}
            onChange={handleChange}
            inputProps={{ placeholder: "Write Note" }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          mt: "19px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="contained"
          onClick={handleSubmit}
          sx={{
            textWrap: "nowrap",
            minWidth: "120px",
            height: "40px",
            fontSize: "14px",
            background: "#4680FF",
            borderRadius: "6.498px",
            boxShadow: "0px 1.625px 0px 0px rgba(0, 0, 0, 0.04)",
            textTransform: "none",
          }}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : "Next"}
        </Button>
      </Box>
    </Box>
  );
};

export default PersonalDetailsForm;
