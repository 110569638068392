import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Alert,
} from "@mui/material";
import QRCodeComponent from "./QR";
import Video from "./Video";
import { useNavigate } from "react-router-dom";
import { submitData } from "../../api/onboarding";
import Instructions from "./Instructions";

const TOTPSetup = () => {
  const [isTotpSetupDone, setIsTotpSetupDone] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const onSubmitClick = () => {
    setLoading(true);
    setError(null);
    try {
      if (window.confirm("Are you sure you want to submit?")) {
        let response = submitData();
        console.log({ response });
        navigate("/onboarding/verification_completed");
      } else {
        return;
      }
    } catch (error) {
      setError(error?.response?.data?.message || "Something went wrong");
      console.error({ error });
    } finally {
      setLoading(false);
    }
  };
  return (
    <Box sx={{ p: 3, textAlign: "left" }}>
      <Instructions />
      <Box
        sx={{
          marginTop: "27px",
          display: "flex",
          justifyContent: "center",
          gap: "115px",
        }}
      >
        <Box>
          <QRCodeComponent
            isTotpSetupDone={isTotpSetupDone}
            setIsTotpSetupDone={setIsTotpSetupDone}
          />
          <Typography
            variant="body2"
            sx={{
              mt: "21px",
              textAlign: "center",
              fontSize: "18px",
              color: "#353535",
              fontWeight: 600,
            }}
          >
            Scan Here to Generate TOTP
          </Typography>
        </Box>
        <Box
          sx={{ width: "1px", height: "433px", background: "#c4c4c4" }}
        ></Box>
        <Box sx={{ width: "523px", height: "290px", textAlign: "center" }}>
          <Video />
          <Typography
            sx={{
              mt: "31px",
              paddingLeft: "26px",
              PaddingRight: "14px",
              fontWeight: 500,
              color: "#353535",
            }}
          >
            See this tutorial video for setting up the TOTP (2-factor
            authentication). Here is the step-by-step tutorial video. It will
            make easy for you to setup the TOTP (2-factor authentication).
          </Typography>
        </Box>
      </Box>
      {error && <Alert severity="error">{error}</Alert>}
      <Box
        sx={{
          mt: "38px",
          display: "flex",
          justifyContent: "flex-end",
          gap: "19px",
        }}
      >
        <Button
          variant="outlined"
          disabled
          sx={{
            textWrap: "nowrap",
            minWidth: "120px",
            height: "40px",
            fontSize: "14px",
            background: "#FFF",
            borderRadius: "6.498px",
            border: "1px solid #6F6F6F",
            color: "#6F6F6F",
            boxShadow: "0px 1.625px 0px 0px rgba(0, 0, 0, 0.04)",
            textTransform: "none",
          }}
        >
          Try another way
        </Button>
        <Button
          variant="contained"
          disabled={!isTotpSetupDone}
          sx={{
            textWrap: "nowrap",
            minWidth: "120px",
            height: "40px",
            fontSize: "14px",
            background: "#4680FF",
            borderRadius: "6.498px",
            boxShadow: "0px 1.625px 0px 0px rgba(0, 0, 0, 0.04)",
            textTransform: "none",
          }}
          onClick={() => onSubmitClick(true)}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : "Submit"}
        </Button>
      </Box>
    </Box>
  );
};

export default TOTPSetup;
