import React from "react";

const video_source =
  process.env.REACT_APP_TOTP_TUTORIAL_VIDEO ||
  "https://cdn.theloans.online/serveka/assets/totp.mp4";
const Video = () => (
  <div className="video-responsive">
    <iframe
      width="523"
      height="290"
      src={video_source}
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    ></iframe>
  </div>
);
export default Video;
