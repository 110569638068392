import CommonDetails from "../CommonComponent/CommonDetails";

const EmiDetails = () => {
  const data = [
    { label: "Amount Remaining", value: "₹21,020/-" },
    { label: "Extra Charges", value: "₹2,020/-" },
    { label: "Amount to be paid", value: "₹23,040/-" },
  ];
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <CommonDetails data={data} buttonName="Pay" />
    </div>
  );
};

export default EmiDetails;
