import CommonForm from "../CommonComponent/CommonForm";

const ResetPassword = () => {
  const fields = [
    {
      name: "Email ID",
      inputPlaceholder: "Enter Email ID",
    },
    {
      name: "Mobile Number",
      inputPlaceholder: "Enter Mobile NUmber",
    },
  ];
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CommonForm
        fields={fields}
        heading={"Enter Credentials"}
        buttonName="Verify"
      />
    </div>
  );
};

export default ResetPassword;
