import { CirclesWithBar } from "react-loader-spinner";
import { Grid } from "@mui/material";

const MyLoader = () => {
  return (
    <Grid
      container
      sx={{
        display: "flex",
        gap: "60px",
        flexWrap: "nowrap",
        mt: "38px",
        textAlign: "left",
        pl: "79px",
        pr: "86px",
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      <Grid item>
        <CirclesWithBar
          height="100%"
          width="100%"
          color="#4fa94d"
          outerCircleColor="#4fa94d"
          innerCircleColor="#4fa94d"
          barColor="#4fa94d"
          ariaLabel="circles-with-bar-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </Grid>
    </Grid>
  );
};

export default MyLoader;
