import { Box, Typography, TextField, Button } from "@mui/material";

const TwoFactorAuthNumber = ({ placeholder, title }) => {
  return (
    <Box
      sx={{
        mt: "60px",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Typography
        sx={{
          fontSize: "24px",
          fontWeight: 600,
          lineHeight: "32px",
          textAlign: "center",
          mb: "15px",
          width: "450px",
        }}
      >
        2-factor authentication
      </Typography>
      <Box
        sx={{
          width: "450px",
          height: "44px",
          fontSize: "14px",
          fontWeight: "400px",
          color: "#5B6B79",
          mb: "63px",
        }}
      >
        {title || "Please enter your whatsapp number for authentication"}
      </Box>
      <TextField
        sx={{
          width: "450px",
          height: "54px",
          marginBottom: "75px",
          textAlign: "center",
          "& .MuiInputBase-input": {
            textAlign: "center",
          },
        }}
        inputProps={{
          placeholder: placeholder || "Enter your whatsapp number",
        }}
      />
      <Button
        variant="contained"
        sx={{
          textWrap: "nowrap",
          minWidth: "208px",
          height: "44px",
          fontSize: "14px",
          background: "#4680FF",
          borderRadius: "6.498px",
          boxShadow: "0px 1.625px 0px 0px rgba(0, 0, 0, 0.04)",
          textTransform: "none",
        }}
      >
        Next
      </Button>
    </Box>
  );
};

export default TwoFactorAuthNumber;
